input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  display: none !important;
}
.table-responsive.pdf-border {
  border: 1px solid black !important;
}

.editor-class {
  height: auto !important;
  min-height: 250px;
}
.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}
@media print {
  .table-responsive.table-bordered.pdf-bordered.tdf,
  th,
  tr,
  tbody,
  td {
    border: 1px solid rgb(209, 202, 202) !important;
  }
}
// .table-responsive.table-bordered.pdf-bordered.tdf,
// th,
// tr,
// tbody,
// td {
//   border: 1px solid black !important;
// }

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
.overflow-div {
  overflow-y: scroll;
  // max-height: 100vh;
}
.overflow-div::-webkit-scrollbar {
  display: none;
}
.fc .fc-toolbar-title {
  font-size: 1.3em;
}

.fc-daygrid-event {
  border: none;
}
.selection-cell {
  display: none;
}
.selection-cell-header {
  display: none;
}
#select-cell .selection-cell {
  display: block !important;
}
#select-cell .selection-cell-header {
  display: block !important;
}

.date_close {
  position: absolute;
  top: 3px;
  right: 20px;
  font-size: 15px !important;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}

.date_close-2 {
  position: absolute;
  top: 3px;
  left: 110px;
  font-size: 15px !important;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}

// .overflowScroll {
//   // overflow-x: scroll;

// }
.max-h-5::-webkit-scrollbar,
.overflowScroll::-webkit-scrollbar {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #444444 !important;
}

.btn.custom_border_rad {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.custome_select_rad .css-yk16xz-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.createClintBtn {
  padding-right: 0px;
}
.createClintBtnCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.borderBottomWhite {
  border-bottom: 1px solid #fff;
}
.myWidth30 {
  width: 30% !important;
}
.myWidth20 {
  width: 20% !important;
}
.width-fit {
  width: fit-content !important;
  margin-left: auto;
}
@media print {
  .bg-info-new {
    color: #000 !important;
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }
  .bg-light-new {
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }
  .print-pb-3 {
    padding-bottom: 2rem;
  }
  .print-pt-3 {
    padding-top: 2rem;
  }
  .print-boxshadow {
    box-shadow: none !important;
  }
  .display-none {
    display: none !important;
  }
  // #header,
  // #footer,
  #nav {
    display: none !important;
  }
}

@media screen and (max-width: 986px) {
  .createClintBtn,
  .createClintBtnCont {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media screen and (max-width: 600px) {
  .d-none-smo {
    display: none;
  }
}
